import $ from 'jquery';

window.jQuery = $;
window.$ = $;

import 'bootstrap';

import Bugsnag from '@bugsnag/js';

if (window.config.bugsnagApiKey) {
    Bugsnag.start({
        autoTrackSessions: false,
        apiKey: window.config.bugsnagApiKey,
        appVersion: window.config.bugsnagAppVersion,
        releaseStage: window.config.appEnvironment,
    });
}
